<template>
  <div class="row">
    <div class="col-12">
      <sales-datatable/>
    </div>
  </div>
</template>

<script>
  import SalesDatatable from "./datatables/SalesDatatable";

  export default {
    name: "SalesIndexPage",
    components: {SalesDatatable},
  }
</script>

<style scoped>

</style>
