<template>
  <octo-table
    show-filters
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.sales"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:username="data">
      {{ data.row | optional('lastname') }}
      {{ data.row | optional('firstname') }}
      <div class="small">{{ data.row.username }}</div>
    </template>

    <template v-slot:campaigns="data">
      <div v-for="(campaign, index) in data.row.campaigns" v-bind:key="`campaign-${index}`">
        {{ campaign.name }}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button
          link
          icon
          class="mx-1"
          size="sm"
          @click="$router.push({name: 'users.sales.show', params: {id : data.row.id }})"
        >
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {mapGetters} from "vuex";

export default {
  name: "SalesDatatable",
  components: {Badge, OctoIcon, OctoTable},
  data() {
    return {
      name: 'SalesDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'username', label: 'username', slot: true, width: 300},
        {prop: 'campaigns', label: 'campaigns', width: 250, slot: true, align: 'center'},
        {prop: 'call_link', label: 'call_link', align: 'center'},
        {prop: 'actions', label: 'actions', width: 100, slot: true},
      ],
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),
  }
}
</script>

<style scoped>

</style>
